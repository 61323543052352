var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","hide-header":"","hide-footer":""},on:{"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "},[_c('h5',{staticClass:"mb-0"},[_vm._v("Add New Positions")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-form',{staticClass:"p-2",on:{"submit":_vm.onSubmit}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Job Title","label-for":"Title"}},[_c('b-form-input',{attrs:{"id":"Title","autofocus":"","trim":"","placeholder":"Job Title"},model:{value:(_vm.companyData.title),callback:function ($$v) {_vm.$set(_vm.companyData, "title", $$v)},expression:"companyData.title"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Company","label-for":"companyId"}},[_c('v-select',{attrs:{"options":_vm.$store.state.job.companies,"reduce":function (val) { return val.id; },"clearable":false,"input-id":"companyId","label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.companyData.companyId),callback:function ($$v) {_vm.$set(_vm.companyData, "companyId", $$v)},expression:"companyData.companyId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Assignee","label-for":"assignee"}},[_c('v-select',{staticClass:"assignee-selector",attrs:{"options":_vm.assigneeOptions,"label":"userFullName","input-id":"userId","multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var userFullName = ref.userFullName;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(userFullName))])]}},{key:"selected-option",fn:function(ref){
var userFullName = ref.userFullName;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(userFullName))])]}}],null,true),model:{value:(_vm.companyData.Users),callback:function ($$v) {_vm.$set(_vm.companyData, "Users", $$v)},expression:"companyData.Users"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Begin Date","label-for":"BeginDateUtc"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"BeginDateUtc","config":{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'd-m-Y',
                }},model:{value:(_vm.companyData.BeginDateUtc),callback:function ($$v) {_vm.$set(_vm.companyData, "BeginDateUtc", $$v)},expression:"companyData.BeginDateUtc"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"End Date","label-for":"EndDateUtc"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"EndDateUtc","config":{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'd-m-Y',
                }},model:{value:(_vm.companyData.EndDateUtc),callback:function ($$v) {_vm.$set(_vm.companyData, "EndDateUtc", $$v)},expression:"companyData.EndDateUtc"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('b-form-textarea',{attrs:{"rows":"3","max-rows":"6","id":"description","trim":""},model:{value:(_vm.companyData.description),callback:function ($$v) {_vm.$set(_vm.companyData, "description", $$v)},expression:"companyData.description"}})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }